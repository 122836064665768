// Polyfills for js features used in the Dashboard but not supported in some browsers (mainly IE)
// import "@/polyfills";
import Notifications from '@/components/Argon/NotificationPlugin';
import GlobalComponents from './global-components';
import GlobalDirectives from './global-directives';

// element ui language configuration
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
locale.use(lang);

// asset imports
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/nucleo/css/nucleo.css';
import '@/assets/css/removify/css/removify.css';
import '@/assets/css/amplify/css/amplify.css';
import '@/styles/_sass.scss';

// Validation plugin used to validate forms
import { extend, setInteractionMode } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { messages } from '@/locale/messages.json';

import VueBrowserUpdate from 'vue-browserupdate';
// import NProgress from "nprogress";

import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

// Aggressive: Triggered when the user presses a key (on input).
// Passive: Triggered when the form is submitted.
// Lazy: Triggered when the user leaves the input (on blur or change).
// Eager: Is a combination of aggressive and lazy, as it first validates when the user leaves the input (on blur or change)
// then if the input is invalid it will behave aggressively until the input is valid again and it will go back to being lazy.
setInteractionMode('lazy');

Object.keys(rules).forEach((rule) => {
	extend(rule, {
		...rules[rule], // copies rule configuration
		message: messages[rule], // assign message
	});
});

export default {
	install(Vue) {
		Vue.use(GlobalComponents);
		Vue.use(GlobalDirectives);
		Vue.use(Notifications);

		// Browser Detection
		Vue.use(VueBrowserUpdate, {
			options: {
				required: { i: 11, f: 25, o: 17, s: 9, c: 22, y: 14 },
				text: `<div class="text-center black"><strong>Your browser is currently not supported.</strong> 
                        For improved <u>security</u>, <u>speed</u>, and <u>user experience</u>, please upgrade.</div>`,
				container: document.body,
			},
		});

		// Progress Bar
		// NProgress.configure({ showSpinner: false });

		// Sentry - bug catcher
		if (!!import.meta.env.VITE_APP_SENTRY_URL) {
			Sentry.init({
				dsn: import.meta.env.VITE_APP_SENTRY_URL,
				integrations: [new Integrations.Vue({ Vue, attachProps: true })],
				ignoreErrors: [
					'ResizeObserver loop limit exceeded',
					'ResizeObserver loop completed with undelivered notifications',
					'Non-Error exception captured',
					'Non-Error promise rejection captured',
					'Illegal invocation',
				],
				beforeSend(event, hint) {
					// recaptcha's asshole timeout issue
					if (hint.originalException === 'Timeout') return null;
					return event;
				},
			});
		}
	},
};
